/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // easy jquery reference variables
  var $document = $(document);
  var $html = $('html');
  var $body = $('body');

  // request animation frame
  var $flags = {
    scrolled: false,
    pauseScrolling: false,
    resized: false,
    resizedWidth: false,
    resizedHeight: false,
    pauseResizing: false,
  };

  var $timers = {
    scroll: -1,
    resize: -1,
    delta: 16.67 //  (1/60fps) / 1000ms = 16.67ms per frame
  };

  var $window = {
    width: window.innerWidth,
    lastWidth: window.innerWidth,
    height: window.innerHeight,
    lastHeight: window.innerHeight,
    scrollX: window.pageXOffset,
    lastScrollX: 0,
    scrollY: window.pageYOffset,
    lastScrollY: 0,
    scrollingDown: true
  };

  /* prepare scroll function */
  window.addEventListener('scroll', function() {
    $flags.scrolled = true;
    $window.scrollX = window.pageXOffset;
    $window.scrollY = window.pageYOffset;

    if($window.scrollY <= $window.lastScrollY) {
      $window.scrollingDown = false;
    } else {
      $window.scrollingDown = true;
    }
    $window.lastScrollY = $window.scrollY;

    if($timers.scroll !== -1) {
      clearTimeout($timers.scroll);
    }

    $timers.scroll = window.setTimeout(function() {
      $flags.scrolled = false;
    }, $timers.delta);
  });

  /* prepare resize function */
  window.addEventListener('resize', function() {
    $flags.resized = true;
    $window.width = window.innerWidth;
    $window.height = window.innerHeight;

    if($window.width !== $window.lastWidth) {
      $flags.resizedWidth = true;
    }
    $window.lastWidth = $window.width;

    if($window.height !== $window.lastHeight) {
      $flags.resizedHeight = true;
    }
    $window.lastHeight = $window.height;

    if($timers.resize !== -1) {
      clearTimeout($timers.resize);
    }

    $timers.resize = window.setTimeout(function() {
      $flags.resized = false;
      $flags.resizedWidth = false;
      $flags.resizedHeight = false;
    }, $timers.delta);
  });

  var requestScrollAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.scrolled) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resized) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeWidthAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resizedWidth) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  var requestResizeHeightAnimation = function(callback, delay) {
    delay = delay || $timers.delta;

    return setInterval(function() {
      if($flags.resizedHeight) {
        window.requestAnimationFrame(callback);
      }
    }, delay);
  };

  // match media variables
  var $mqXL = '(min-width: 1281px)';
  var $isXL = window.matchMedia($mqXL);

  var $mqLG = '(min-width: 1025px)';
  var $isLG = window.matchMedia($mqLG);

  var $mqMD = '(min-width: 769px)';
  var $isMD = window.matchMedia($mqMD);

  var $mqSM = '(min-width: 641px)';
  var $isSM = window.matchMedia($mqSM);

  // browser detection
  var $ua = navigator.userAgent;

  if($ua.indexOf('Safari') !== -1 && $ua.indexOf('Chrome') === -1) {
    $html.addClass('safari');
  }

  if($ua.indexOf('Chrome') !== -1) {
    $html.addClass('chrome');
  }

  if($ua.match(/iP(hone|od|ad)/i) ) {
    $html.addClass('browser-ios');
  }

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test($ua)) {
    $html.addClass('browser-mobile');
  }

  // check sticky header
  function checkStickyHeader() {
    if(!$flags.pauseScrolling) {
      if($window.scrollY <= 0) {
        $html.removeClass('state--show-sticky-header');
      } else {
        if($window.scrollingDown) {
          $html.removeClass('state--show-sticky-header');
        } else {
          $html.addClass('state--show-sticky-header');
        }
      }
    }
  }
  requestScrollAnimation(checkStickyHeader);

  // set js cookie
  function setCookie(name, value, days) {
    var expires = '';

    if(days) {
      var date = new Date();
      date.setTime(date.getTime()+(days*24*60*60*1000));
      expires = '; expires='+date.toGMTString();
    }

    document.cookie = name+'='+value+expires+'; path=/';
  }

  // get js cookie
  function getCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');

    for(var i=0; i < ca.length; i++) {
      var c = ca[i];

      while(c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }

      if(c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length,c.length);
      }
    }

    return false;
  }

  // convert viewport units to pixels
  function toPX(value) {
    return parseFloat(value) / 100 * (/vh/gi.test(value) ? window.innerHeight : window.innerWidth);
  }

  // update menu indicator
  function updateMenuIndicator() {
    var $activeMenuItem = $('.menu .menu-item.active');
    var $indicatorLeft = 0;
    var $indicatorWidth = 0;

    if($activeMenuItem.length) {
      $indicatorLeft = $activeMenuItem.position().left;
      $indicatorWidth = $activeMenuItem.width();
    }

    $('.menu').get(0).style.setProperty('--indicator-left', $indicatorLeft+'px');
    $('.menu').get(0).style.setProperty('--indicator-width', $indicatorWidth+'px');
  }

  // setup gravity forms
  function setupGravityForms() {
    $('.gfield:not(.gf_list_inline)').each(function() {
      var $gfield = $(this);
      var $label = $gfield.find('.gfield_label');
      var $field = $gfield.find('input, textarea');
      var $placeholder = $field.attr('placeholder');
      var $hasPlaceholder = false;
      if(typeof $placeholder !== 'undefined' && $placeholder !== '') {
        $hasPlaceholder = true;
      }

      if($field.val() !== '' || $hasPlaceholder) {
        $label.addClass('shrink');
      }

      if($field.val() !== '') {
        $field.addClass('not-empty');
      } else {
        $field.removeClass('not-empty');
      }

      $field.focus(function() {
        $label.addClass('shrink');
      }).blur(function() {
        if($field.val() === '' && !$hasPlaceholder) {
          $label.removeClass('shrink');
        }

        if($field.val() !== '') {
          $field.addClass('not-empty');
        } else {
          $field.removeClass('not-empty');
        }
      });
    });

    $('.contact-content_form form').submit(function() {
      $(this).find('.btn').addClass('state--submitting');
      $(this).find('.btn .btn-text').html('Submitting...');
    });
  }

  var $options = [
    {
      from: '(.*)',
      to: '(.*)',
      in: function(next) {
        UTIL.loadEvents();

        // run any scripts included in swup content
        $('#swup script').each(function() {
          var $originalEl = this;
          var $el = document.createElement('script');

          $.each($originalEl.attributes, function(i, $attr) {
            var $name = $attr.name;
            var $value = $attr.value;

            $el.setAttribute($name, $value);
          });

          $el.textContent = $originalEl.textContent;

          $originalEl.replaceWith($el);
        });

        setupGravityForms();

        setTimeout(function() {
          window.scrollTo(0, 0);
          $html.removeClass('is-loading');
          $flags.pauseScrolling = false;
        }, 250);
      },
      out: function(next, infos) {
        $flags.pauseScrolling = true;

        // reset classes
        $html.addClass('is-loading');
        $html.removeClass('state--mobile-menu-open');
        $html.removeClass('state--team-overlay-open');

        // adjust menu indicator
        $('.menu .menu-item.active').removeClass('active');

        if(infos.transition.to !== '/') {
          $('.menu a[href*="'+infos.transition.to+'"]').parent().addClass('active');
        }
        updateMenuIndicator();

        // go to next
        next();
      }
    }
  ];

  var $swup = new Swup({
    animateHistoryBrowsing: true,
    plugins: [
      new SwupGaPlugin({
        gaMeasurementId: 'UA-70312-49',
      }),
      new SwupBodyClassPlugin(),
      new SwupJsPlugin($options),
      new SwupPreloadPlugin()
    ]
  });

  function initCommon() {

  }

  function initHome() {

  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: initCommon
    },
    'home': {
      init: initHome
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  function init() {
    // loaded
    $(window).on('load', function() {
      $html.removeClass('is-loading');
    });

    UTIL.loadEvents();

    // gravity form functionality
    $document.on('gform_post_render', setupGravityForms);

    // initialize menu indicator
    updateMenuIndicator();

    // mobile menu
    $document.on('click', '.mobile-menu_toggle', function(e) {
      e.preventDefault();

      if($html.hasClass('state--mobile-menu-open')) {
        $html.removeClass('state--mobile-menu-open');
      } else {
        $html.addClass('state--mobile-menu-open');
      }
    });

    // team overlay
    $document.on('click', '.team-card', function(e) {
      e.preventDefault();

      var $card = $(this);
      var $overlayContent = $card.next();

      $('.team-overlay .team-overlay_content').html($overlayContent.html());

      $html.addClass('state--team-overlay-open');
    });

    $document.on('click', '.team-overlay_close, .team-overlay_mask', function(e) {
      e.preventDefault();

      $html.removeClass('state--team-overlay-open');
    });
  }

  init();

})(jQuery); // Fully reference jQuery after this point.
